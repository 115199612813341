import React, { useRef } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import emailjs from '@emailjs/browser';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-10 md:py-12 items-center  sm:flex flex-col-reverse `;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-4/12 flex-shrink-0 relative pt-10`;
const TextColumn = styled(Column)(props => [
  tw`md:w-5/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;


const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left pb-1`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-1 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-black`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);



const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;


export default ({
  subheading = " ",
    submitButtonText = "Skicka",
  heading = (
    <>
      
    </>
  ),

  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_h8hbsif', 'template_3vs39on', form.current, 'uWjHEjE6fmTgGPhWA')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
    <Container>

   
    <TwoColumn>
      
      <ImageColumn>
      <Description style={{color: 'rgb(124 139 161 / var(--tw-text-opacity))'}}>
      {subheading && <Subheading  tw="text-secondary-500 text-lg" >Tveka inte att kontakta oss om du har några funderingar. Använd formuläret eller besök oss på Lignells väg 1, 136 49 Haninge. <br/>Vi talar även Engelska och Spanska.</Subheading>}
      {subheading && <Subheading  tw="text-orange-500">ÖPPETTIDER</Subheading>}
      <br/>


            Måndag-Torsdag: 09.00-18.00<br/>
            Fredag: 09.00-17.00<br/>
            Lördag: 11.00-15.00<br/>
            {subheading && <Subheading  tw="text-orange-500">TELEFON</Subheading>}
           08 - 400 212 95<br/>
           {subheading && <Subheading  tw="text-orange-500">E-POST</Subheading>}
            info@solidbil.se<br/>
            </Description>
          <iframe tw="justify-center py-10 w-full"  maxWidth="350" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=Lignells%20v%C3%A4g%201,%20136%2049%20Haninge&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
      </ImageColumn>


      <TextColumn textOnLeft={textOnLeft}>
        <TextContent>


        {subheading && <Subheading  tw="text-orange-500">Skriv till oss</Subheading>}
       
          <Heading>Vi svarar inom <br/>kort!</Heading> 
        <Form ref={form} onSubmit={sendEmail}>
              <Input type="text" name="user_name" placeholder="Namn" />
              <Input type="email" name="user_email" placeholder="E-post" />
              <Textarea name="message" placeholder="Ditt meddelande" />
              <SubmitButton tw="bg-orange-500" type="submit" value="Send">{submitButtonText}</SubmitButton>
            </Form>
        </TextContent>
      </TextColumn>
    </TwoColumn>
  </Container>
    // <Container>
    
    //   <TwoColumn>

    //     <ImageColumn textOnLeft={textOnLeft}>
    //       <TextContent>
    //         <Subheading>{subheading}</Subheading>
    //         <Heading>{heading}</Heading>

            // <Description tw="uppercase font-bold">Öppettider</Description>
            // <Description><span tw="font-semibold font-black">Måndag-Torsdag:</span>  09.00-18.00.</Description>
            // <Description><span tw="font-semibold">Fredag:</span> 09.00-17.00</Description>
            // <Description><span tw="font-semibold">Lördag:</span> 11.00-15.00</Description>
            // <Description tw="uppercase font-bold">Telefon:</Description>
            // <Description>08 - 400 212 95</Description>
            // <Description tw="uppercase font-bold">Epost:</Description>
            // <Description>info@solidbil.se</Description>
    //         {/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
    //           {primaryButtonText}
    //         </PrimaryButton> */}
    //       </TextContent>
    //     </ImageColumn >

    //     <TextColumn textOnLeft={textOnLeft}>
          // <TextContent>
          // {subheading && <Subheading  tw="text-orange-500">Skriv till oss</Subheading>}
          //   <Heading>Vi svarar inom kort!</Heading> 
            // <Form ref={form} onSubmit={sendEmail}>
            //   <Input type="text" name="user_name" placeholder="Namn" />
            //   <Input type="email" name="user_email" placeholder="E-post" />
            //   <Textarea name="message" placeholder="Ditt meddelande" />
            //   <SubmitButton tw="bg-orange-500" type="submit" value="Send">{submitButtonText}</SubmitButton>
            // </Form>
    //       </TextContent>
    //     </TextColumn>
    //   </TwoColumn>
      
    // </Container>
  );
};













// import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import EmailIllustrationSrc from "images/email-illustration.svg";


// const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-12`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
// const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
// const TextColumn = styled(Column)(props => [
//   tw`md:w-7/12 mt-16 md:mt-0`,
//   props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
// ]);

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`,
// ]);
// const TextContent = tw.div`lg:py-8 text-center md:text-left`;

// const Subheading = tw(SubheadingBase)`text-center md:text-left`;
// const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`


// const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
// const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
// const Textarea = styled(Input).attrs({as: "textarea"})`
//   ${tw`h-24`}
// `

// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

// const SlantedBackground = styled.span`
//   ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
//   &::before {
//     content: "";
//     ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
//   }
// `;

// export default ({
//     subheading = "Kontakta oss",
//     heading = <>Skriv till oss, <span tw="text-orange-500">vi återkommer</span><wbr/> inom kort.</>,
//   description = "",
//   submitButtonText = "Skicka",
//   formAction = "#",
//   formMethod = "get",
//   textOnLeft = true,
// }) => {
//   // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
 
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('service_h8hbsif', 'template_3vs39on', form.current, 'uWjHEjE6fmTgGPhWA')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//       e.target.reset()
//   };


//   return (
//     <Container>
//       <TwoColumn tw='lg:flex-row sm:flex flex-col'>
//         <TextColumn textOnLeft={textOnLeft}>
//           <TextContent>
//             {subheading && <Subheading  tw="text-orange-500">{subheading}</Subheading>}
//             <Heading>{heading}</Heading>
//             {description && <Description>{description}</Description>}
            
//             <Form ref={form} onSubmit={sendEmail}>
//               <Input type="text" name="user_name" placeholder="Namn" />
//               <Input type="email" name="user_email" placeholder="E-post" />
//               <Textarea name="message" placeholder="Ditt meddelande" />
//               <SubmitButton tw="bg-orange-500" type="submit" value="Send">{submitButtonText}</SubmitButton>
//             </Form>
//           </TextContent>
//         </TextColumn>
//         {/* <ImageColumn> */}
//           {/* // <Image imageSrc={EmailIllustrationSrc} /> */}
// {/*         
//         <ul tw='flex flex-col self-center text-right'>
//           <li tw='text-lg text-orange-500 uppercase font-bold'>öppettider</li>
//           <li> <span tw='font-semibold'> Måndag-Torsdag:</span> 09.00-18.00</li>
//           <li> <span tw='font-semibold'> Fredag:</span> 09.00-17.00</li>
//           <li> <span tw='font-semibold'> Lördag:</span> 10.00-14.00</li>
//           <li tw='text-lg text-orange-500 uppercase font-bold'>adress</li>
//           <li>Lignellsväg 1 , Haninge</li>
//           <li tw='text-lg text-orange-500 uppercase font-bold'>E-post</li>
//           <li>info@solidbil.se</li>
//           <li tw='text-lg text-orange-500 uppercase font-bold'>Telefon:</li>
//           <li>08-40021295</li>
//         </ul>
//       </ImageColumn> */}
//       </TwoColumn>
//     </Container>
//   );
// };


