import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import { Content2Xl } from "components/misc/Layouts";
import CaravanBlock from "./CaravanBlock";
import MapSection from "./MapSection";
import LastestCars from "components/features/LastestCars";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import sig from "images/sig.png";

export default () => (
  <AnimationRevealPage disabled>
    <Hero />
    <MainFeature />
    {/* <CaravanBlock /> */}
    
    <LastestCars />
    <Features />     
    {/* <FAQ
      imageSrc={"https://cdn.pixabay.com/photo/2018/05/01/19/57/call-center-3366790_960_720.png"}
      imageContain={true}
      imageShadow={false}  
      description={
        <>
          Nedan finner du svaren på några av våra vanligaste frågor. Finns din fråga inte med så är du alltid välkommen att <span style={{color: 'rgb(255 128 0 / var(--tw-text-opacity))', textDecoration:'underline'}}><a href="/kontakt">Kontakta oss.</a></span> Vi hjälper dig gärna!
        </>
      }
      subheading="Frågor och svar"
      heading={
        <>
          Har du <span tw="text-primary-500">frågor ?</span>
        </>
      }
    />  */}
    <ContactUsForm />
    <MapSection/>    
    <center><img src={sig} alt="signature" tw="mx-auto" /></center>
    <br></br>
    <br></br>
    <br></br>
    <Footer />
  </AnimationRevealPage>
);
