import { createGlobalStyle } from 'styled-components'
import  { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }

   .light__NavLink-lj69nl-2 {
      color: white !important;
    }

    .BackgroundAsImage__SlantedBackground-sc-64ha7n-8
    {
      color: #F98D38 !important;
    }
   
    .BackgroundAsImage__PrimaryAction-sc-64ha7n-10{
      color: #F98D38 !important;
    }

    .TwoColSingleFeatureWithStats2__Subheading-sc-1xj5bjq-10 {
      color: #F98D38 !important;
    }

    .TwoColSingleFeatureWithStats2___StyledSpan-sc-1xj5bjq-20 {
      color: #F98D38 !important;
    }

    .DashedBorderSixFeatures___StyledSpan-gqpkf2-6 {
      color: #F98D38 !important;
    }

    .title {
      color: #F98D38 !important;
    }

    .TwoColumnWithImageAndProfilePictureReview__Subheading-cbjyad-13 {
      color: #F98D38 !important;
    }

    .Home___StyledSpan-sc-1fn5sw7-0 {
      color: #F98D38 !important;
    }

    .SimpleWithSideImage__Subheading-yk61ja-7 {
      color: #F98D38 !important;
    }

    .Home___StyledSpan2-sc-1fn5sw7-1 {
      color: #F98D38 !important; 
    }


    .TwoColContactUsWithIllustration__Subheading-ihjnfb-7 {
      color: #F98D38 !important; 
    }

    .TwoColContactUsWithIllustration___StyledSpan-ihjnfb-13 {
      color: #F98D38 !important; 
    }

    .LastestCars___StyledSpan-pv6iog-5 {
      color: #F98D38 !important;
    }

`)

export default GlobalStyles